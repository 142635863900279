<template>
	<list-template
			:current-page='page'
			:isDisabled="{ exportData: !search}"
			:loading="loading"
			:search-config='searchConfig'
			:table-config='tableConfig'
			:table-data='tableData'
			:total='total'
			@onChangePage='changeCurrentPage'
			@onExportData="download"
			@onHandle='tableHandle'
			@onReset='onReset'
			@onSearch='onSearch'
      @onChange="onChange"
	>
	</list-template>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import { setConfigValue } from '@/utils'
export default {
	_config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
	computed: {
		...mapState(['page']),
		...mapGetters(['userInfo'])
	},
	data() {
		return {
			loading: true,
			search: {},
			total: 0,
			searchConfig: [
				{
					prop: 'school_id', tag: "select", placeholder: '筛选校区', options: [], label: 'name', value: 'id',
					change: (val, search) => {
						search.grade_id = ''
						search.class_id = ''
						this.$_axios2.get('api/common/school', {params: {level: 2, school_id: val}}).then(res => {
							let data = res.data.data
							this.searchConfig[1].options = data.action_grade
							this.searchConfig[2].options = data.teaching_data
						})
					}
				},
				{
					prop: 'grade_id', tag: "select", placeholder: '筛选年级', options: [], label: 'name', value: 'id',
					change: (val, search) => {
						search.class_id = ''
						if (search?.school_id)
							this.$_axios2.get('api/common/school', {params: {level: 2, school_id: search.school_id, grade_id: val}}).then(res => {
								let data = res.data.data
								this.searchConfig[2].options = data.teaching_data
							})
						else
							this.$_axios2.get('api/common/school', {params: {level: 2, grade_id: val}}).then(res => {
								let data = res.data.data
								this.searchConfig[2].options = data.teaching_data
							})
					}
				},
				{
					prop: 'class_id', tag: "select", placeholder: '筛选班级', options: [], label: 'name', value: 'id',
				},
				{
					prop: 'teacher_score_at',
					tag: 'datePicker',
					default: [],
					property: {
						size: 'small',
						style: 'margin-right:20rem;',
						type: 'daterange',
						valueFormat: 'yyyy-MM-dd',
						startPlaceholder: '开始日期',
						endPlaceholder: '结束日期'
					}
				},
				{
					prop: 'subject_id', tag: "cascader", placeholder: '筛选学科', options: [],
					config: {
						showAllLevels: false
					},
				},
				{prop: 'teacher_name', placeholder: '搜索教师姓名'},
				{prop: 'student_name', placeholder: '搜索学生姓名', default: this.$route.query.student_name},
			],
			tableData: [],
			tableConfig: [
				{prop: 'student_name', label: '学生姓名'},
				{prop: 'student_no', label: '学生学号'},
				{prop: 'teacher_name', label: '辅导老师'},
				{prop: 'subject_name', label: '辅导学科'},
				{prop: 'teacher_score_at', label: '提交日期', width: 180},
				{prop: 'class_name', label: '教学班'},
				{prop: 'grade_name', label: '年级'},
				{prop: 'school_name', label: '学校'},
				{prop: 'handle', label: '操作', width: '80rem', handle: true, buttons: [{type: "view", text: "查看"}]}
			]
		}
	},
	created() {
		this.init()
	},
  activated() {
    this.init()
  },
	methods: {
		...mapMutations(['setPage']),
    onChange(val) { this.search = val },
    init() {
      const name = this.$route.query.student_name
      if (name) {
        setConfigValue({ list: this.searchConfig, key: 'student_name', changeKey: 'default', value: name })
        this.search.student_name = name
      }
      this.$store.commit('setPage', 1)
      this.getData()
      this.$_axios2.get('api/common/school', {params: {level: 2}}).then(res => {
        let data = res.data.data
        this.searchConfig[0].options = data.school
      })
      this.$_axios2.get('api/common/subject', {params: {level: 3}}).then(res => {
        this.searchConfig[4].options = JSON.parse(
            JSON.stringify(res.data.data)
                .replace(/id/g, 'value')
                .replace(/subject_name/g, 'label')
                .replace(/children/g, 'children')
        )
        this.$forceUpdate()
      })
    },
		// 获取列表基础数据
		getData() {
			setTimeout(() => {
				this.loading = true
				let params = this.search ? JSON.parse(JSON.stringify(this.search)) : {}
				if (params.teacher_score_at && params) {
					params.teacher_score_at = params.teacher_score_at.join('~')
				}
				if (params.subject_id && params) {
					params.subject_id = params.subject_id.pop()
				}
				this.$_axios2.get('api/coach-record/list', {params: {...params, page: this.page}}).then(res => {
					this.tableData = res.data.data.list
					this.total = res.data.data.page.total
					this.searchConfig[6].default = ''
				}).finally(() => {
					this.loading = false
				})
			}, 10)
		},
		onSearch(val) {
			this.$store.commit('setPage', 1)
			this.search = val
			this.getData()
		},
		changeCurrentPage(e) {
			this.$store.commit('setPage', e)
			this.getData()
		},
		download() {
			let params = this.search ? JSON.parse(JSON.stringify(this.search)) : {}
			if (params.teacher_score_at && params) {
				params.teacher_score_at = params.teacher_score_at.join('~')
			}
			if (params.subject_id && params) {
				params.subject_id = params.subject_id.pop()
			}
			this.$_axios2.get('api/coach-record/list-export', {params: {...params}}).then(res => {
				this.$tools.download('辅导记录', res.data)
			})
		},
		tableHandle(row) {
			this.$router.push('./look?id=' + row.id)
		},
		onReset() {
			this.searchConfig[6].default = ''
			this.searchConfig[1].options = []
			this.searchConfig[2].options = []
			setConfigValue({ list: this.searchConfig, key: 'keyword', changeKey: 'default' })
      this.search = {}
			this.$_axios2.get('api/common/school', {params: {level: 2}}).then(res => {
				let data = res.data.data
				this.searchConfig[0].options = data.school
			})
		},
	}
}
</script>

<style scoped></style>
